import React from "react";

export default function DisplayPost(props) {

return (

    <div className="content">
                        
        <div id="section1" className="post-content">
            <p dangerouslySetInnerHTML={{__html: props.postIntro}} />
        </div>

        <div className="post-image-wrapper">
            <img src={props.introImage} alt={props.intro_image_alt} className="post-image object-fit-scale" />
        </div>

        {props.postContent.map(content => {
            return (
                <>
                    <div id="section2" className="post-content">
                        <h2>{content.sectionTitle}</h2>
                        <p dangerouslySetInnerHTML={{__html: content.paragraph}} />
                        {!content.quote ? null : <div className="quote"><i class="fas fa-quote-left"></i>{content.quote}<i class="fas fa-quote-right"></i></div>}
                    </div>
                    <div className="post-image-wrapper">
                        {!content.image ? null : <img src={content.image} alt={content.image_alt} className="post-image object-fit-scale" />}
                    </div>

                </>
            )
        })}
        
        <div id="conclusion" className="post-content">
            <h2>Conclusion</h2>
            <p dangerouslySetInnerHTML={{__html: props.postConclusion}} />
        </div>
    </div>
    );
}
;