import React from "react";

function BlogJumbo(data) {

  const blogJumbo = {
    height: '60vh',
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/BlogMedia/${data.img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    boxShadow: 'var(--bigBoxShadow)'
  };
  

  return (
    <>
      <div style={blogJumbo} className="rockBackground">

        <div className="jumboCopy flex-column">

            <h1>{data.h1}</h1>
            <h3 className="mt-3">{data.p}</h3>
            {!data.additionalHTML ? "" : <div id="google_translate_element"></div>}
            
        </div>
      </div>
    </>
  );
};

export default BlogJumbo;