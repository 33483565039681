export const Locations = [
    "Axminster",
    "Bath",
    "Barnstaple",
    "Bridport",
    "Bridgwater",
    "Bristol",
    "Chard",
    "Cheltenham",
    "Cornwall",
    "Devon",
    "Dorchester",
    "Dorset",
    "East-Devon",
    "Exeter",
    "Exmouth",
    "Gloucestershire",
    "Honiton",
    "Ilminster",
    "Launceston",
    "Lyme-Regis",
    "North-Devon",
    "Plymouth",
    "Seaton",
    "Sidmouth",
    "Somerset",
    "Taunton",
    "Tavistock",
    "Truro",
    "Wellington",
    "Yeovil",
]