import moment from "moment";
import React from "react";
import { NavLink } from "react-router-dom";


export default function PostCardSmall (BlogPost) {

    const post = BlogPost.post

    return (
        <div className="col-md-6 mb-5">
            <div className="card shadow-lg h-100">
                <img src={post.introImage} className="card-img-top object-fit-cover" style={{minHeight: 35 + 'vh'}} alt={post.postTitle} />
                <div className="card-body d-flex flex-column justify-content-between">
                    <strong className="d-inline-block mb-2 text-primary-emphasis">{post.tags.join(' | ')}</strong>
                    <h3 className="mb-0 text-brand-purple">{post.postTitle}</h3>
                    <div className="mb-1 text-body-secondary">{moment(post.date).format('MMMM Do YYYY')}</div>
                    <NavLink to={'/my-stuff/'+post.url} className="mt-3 btn btn-primary  gap-1 icon-link-hover stretched-link">
                        Continue reading...
                    </NavLink>
                </div>
            </div>
        </div>
    );
};
