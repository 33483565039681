export const BlogPosts = [
    {
        id: 1,
        url: "samsung-galaxy-book-pro-360-cracked-screen",
        meta_title: "Warning About Samsung Galaxy Book Pro 4 360 - Screen Randomly Cracks",
        meta_desc: "Don't buy a Galaxy Book! Read about my ongoing battle with Samsung over their latest flagship's fragile screen that 'randomly' cracks.",
        postMainImage: "/assets/images/BlogMedia/galaxy-book-4/galaxy-book-4-pro-cracked-screen.jpg",
        main_image_alt: "A Galaxy Book 4 Pro 360 with a cracked screen",
        postTitle: "Samsung Galaxy Book4 Pro 360 - Steer Clear or Pay £550 to Fix!",
        postDescription: "A Warning About Samsung's fragile flagship laptop with a screen that randomly cracks! - However it breaks, Samsung's 'Terms & Conditions' are written to cover them against anything.",
        postIntro: `
            I recently purchased the Galaxy Book 4 Pro 360 as I needed something with enough specs to handle the running of my growing business as well as the demands of my new coding career. At first glance, the laptop is sleek, stylish, and more than up to the task. However, after only two months, while coding, I noticed a hairline crack running diagonally from the bottom left to the top right of the screen. Samsung outright refuse to accept that there is a problem with the screen, and that ANY and ALL breaks to the screen are caused by the user therefore not covered by the warranty.`,
        introImage: "/assets/images/BlogMedia/galaxy-book-4/galaxy-book-4-pro-cracked-screen.jpg",
        intro_image_alt: "A Galaxy Book 4 Pro 360 with a cracked screen",
        tags: ["Tech"],
        date: "2024-09-07T21:30:00",
        featured: true,
        postContent: [
            {
                sectionTitle: `"You just broke it" - I hear you say`,
                paragraph: `
                    I've been blitzing Samsung's paid social media ads with the same comment, warning people not to buy and explaining what happened since July 2024. I was surprised at how many other people have had similar experiences with Samsung. But of course, there have been the odd few who told me I broke it myself, which makes me laugh! Thanks, social media police, for your hard work and diligence! 🤣 I can honestly say that I did not damage this laptop by dropping it, knocking it, or anything else. I mean, look at my website, the services I offer, I'm a nerd, a geek; I look after nice tech! 
                        <br />
                        <br />
                    I am happy to accept responsibility when I break something; however, after racking my brains, I realised I did not drop, dent, scratch, or bash the laptop, having it stored in a Samsung faux leather sleeve inside a laptop bag at all times when not in use. The laptop itself is still, after six months, in perfect condition with not even a scratch
                `,
                quote: null,
                image: null,
                image_alt: "",
            },
            {
                sectionTitle: "Multiple Complaints",
                paragraph: `
                    While it is impossible to count just how many complaints there have been about this issue, you only have to search for the problem on Google or Facebook to see a common theme. There have been enough complaints since the Book 2 series to prompt legal investigations by a law firm called Shub Law into the possibility of a design defect (<a target="_blank" href="https://shublawyers.com/current-investigations/samsung-galaxy-book-pro2-360/">Here's their page</a>). Samsung stands by its terms and conditions: 'The screen is not covered by warranty.' What is strange to me is just how mysterious the circumstances of my broken screen are. I only realised it was cracked by complete chance.
                `,
                quote: `No matter the circumstances, any break to the Galaxy Book 4 Pro 360 screen is not covered by warranty!`,
                image: "/assets/images/BlogMedia/galaxy-book-4/Chat GPT Response To Counting Cracked Screen Complaints.jpg",
                image_alt: "An AI generated list of forums where cracked Galaxy Book 4 Pro 360 screen complaints have been discussed",

            },
            {
                sectionTitle: "Samsung Covered Themselves",
                paragraph: `
                    Long story short, Samsung's very own terms & conditions ensures they are never at fault for a broken screen - EVER! At first I thought that I'll tell Samsung and they'll take it in and fix the issue. But the rep dealing with my case resorted to 'I am very sorry to say but Samsung stands by its terms and conditions'. I emphasised that the screen is not fit for purpose, that a touchscreen made to be handled (it's a 360 screen) should not just break. His response? 'I am very sorry to say but Samsung stands by its terms and conditions'. It is not covered by the warranty.
                `,
                quote: "I emphasised that the screen is not fit for purpose, that a touchscreen made to be handled (it's a 360 screen) should not just break.",
                image: "",
                image_alt: "",

            },
            {
                sectionTitle: "£550 Repair Quote",
                paragraph: `
                    When I made the complaint, Samsung customer service warned me that the outcome will inevitably be that I have to cover the cost as the screen is not covered by warranty. They said I should send it to their repair partner, DigiLand EU, who will assess it and let us know the outcome. Their reply:
                        <br>
                        <br>
                    <div style="font-style: italic; margin: 0 20px 0 20px">
                        <p>Good Afternoon Robert,</p>
                        <p>We have received and inspected your laptop and it has been deemed out of warranty due to the cracked screen. Please find a quote below.</p>
                        Logistics: £40.00
                            <br />
                        Labour: £80.00
                            <br />
                        Screen: £436.11
                            <br />
                        Total: £556.11 inc VAT
                            <br />
                            <br />
                    </div>
                    Given that the laptop itself costs around £1,800 new, £550 to repair it is not an insignificant cost. The screen is of course a major part of the laptop, being touch sensitive, s-pen compatible as well as being able to swivel a full 360 degrees makes it a really cool and useful laptop. It is extremely thin and appears to be mounted on a sheet of metal not much thicker than the glass display. So it is not a cheap repair!
                        <br />
                        <br />
                    While it comes to mind, I asked Samsung's repair partner, Digiland EU, for a document/report to confirm the pristine condition of the laptop, even offering to pay for the report, but they refused saying it is not a service they provide. In the UK, I'm told, the seller has to prove that I caused the physical damaged. Since the laptop is pristine condition, I want to know what the repair company told Samsung for them to conclude it is physical damage caused by myself. It seemed extremely odd and suspicious to me that Digiland did not want to comment on the actual condition of the laptop.
                        <br />
                        <br />
                    I have submitted a freedom of information request requesting all data relating to communication between Samsung and DigiLand EU as well internal comms regarding the condition of my laptop. They have acknowledged my request and will respond by the end of September 2024
                `,
                quote: `Whether you broke it yourself or are have fallen victim to the 'phantom cracking' be prepared to spend over £550 to fix the screen`,
                image: "/assets/images/BlogMedia/galaxy-book-4/galaxy_book_4_pro_360_top.jpg",
                image_alt: "The top of the Galaxy Book 4 Pro 360 with no evidence of physical damage",

            },
            {
                sectionTitle: "Absolute Let-Down By a Trusted Brand",
                paragraph: `
                    I was going to just drop it and live with a broken screen for the remainder of my finance contract, but it doesn't sit right! After learning that this has been a defect present through the models and that loads of others have been affected I just got angry! When I bought the Galaxy Book 4 Pro 360, I did so believing I was buying a premium product form a trusted brand. I blindly bought it without researching because it is Samsung because my experience with Samsung phones (the S-Ultra series) has been nothing but positive.
                        <br />
                        <br />
                    Unfortunately this experience, the clear denial and coverup from Samsung has left me completely disillusioned with them. I am frustrated because their S-Ultra phones, Bud Pro Headphones and even the Watches suit me perfectly, but I will be switching to other brands when the time comes. I refuse to support a company whose corporate greed is getting the better of them. Own Up Samsung! Clearly Samsung should stick to microwaves and leave the laptops to the less arrogant professional brands!
                `,
                quote: `It doesn't sit right that big companies can get away with poor quality and service just by writing Terms & Conditions that serve no other purpose but to absolve them of any accountability`,
                image: "",
                image_alt: "",

            },            {
                sectionTitle: "What Samsung Should Have Done",
                paragraph: `
                    Ok, so I completely understand Samsung have to protect themselves from users who make false warranty claims where they have clearly caused the damage. This would be obvious and would surely include the following evidence (non-exhaustive):
                        <ul style="text-align: left;">
                            <li><strong>Impact Points or Dents</strong></li>
                            <li><strong>Scratches on the Screen or Body</strong></li>
                            <li><strong>A Bent or Warped Frame</strong></li>
                            <li><strong>"Spider Web" Cracks (from a drop)</strong></li>
                            <li><strong>Signs of Excessive Force or Twisting</strong></li>
                            <li><strong>Water Ingress</strong></li>
                        </ul>
                        <br />
                    Since my Galaxy Book 4 has no evidence of the above and I am being told I caused the damage, it is clear Samsung are not assessing these on a case by case basis. Samsung needs to acknowledge this growing problem and defect in their devices, and offer a reasonable solution. Instead they are hiding behind their legislation to save face and protect profits. They need to own up to the issue and do what all the other major manufacturers across many industries do when they encounter a manufacturing fault and either recall the products or fix them free of charge.  

                `,
                quote: `All companies make mistakes at some point, no one is perfect and unexpected design flaws happen. However, a company has an obligation to be transparent about it and sort it!`,
                image: "/assets/images/BlogMedia/galaxy-book-4/galaxy_book_4_pro_360_bottom.jpg",
                image_alt: "The bottom of the Galaxy Book 4 Pro 360 with no evidence of physical damage",

            },
        ],
        postConclusion: `
            Do not buy the the Samsung Galaxy Book 4 Pro 360. Like its predecessors, the Galaxy Book 360 series could have been absolute gems! As computers go they are great spec and they look sexy! I genuinely hope other owners don't encounter the problem that myself and others have. But for those of us who have experienced the mysterious screen cracks, it's an expensive purchase gone wrong and a risk I will never take again! 
                <br />
                <br />
            Samsung's immediate and assertive blaming of the customer, the suspicious communication with its repair partner, and a fundamental flaw in the rotating touchscreen - the laptops main selling point- has left a very bitter taste. Sadly, I cannot in good faith recommend the Galaxy Book 4 Pro 360, or even Samsung as a brand!
        `
    },
]