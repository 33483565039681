import Jumbotron from '../../components/Jumbotron/Jumbotron.js';
import './BlogStyles.css'
import DisplayPost from '../../components/DisplayPost/DisplayPost.js';
import { Helmet } from 'react-helmet';

export default function BlogPage(props) {

    return (
        <>
            <Helmet>
                <title>{props.blog.meta_title}</title>
                <meta name="title" content={props.blog.meta_title} />
                <meta name="description" content={props.blog.meta_desc} />
                <meta property="og:title" content={props.blog.meta_title} />
                <meta property="og:description" content={props.blog.meta_desc} />
                <meta property="og:url" content={`https://www.rob-saunders.co.uk/my-stuff/${props.blog.url}`}/>
                <meta property="og:type" content="website"/>
            </Helmet>

            <Jumbotron 
                h1={props.blog.postTitle}
                p={props.blog.postDescription}
            />  

            <main className='blog-body'>
                <div className='blog-container heavy-shadow'>
                   
                   {DisplayPost(props.blog)}
                   
                </div>
            </main>
        </>
    )
};


{/* <div class="sidebar">
    <h3>Blog Sections</h3>
    <ul>
        <li><a href="#section1"><i class="fas fa-home"></i> Introduction</a></li>
        <li><a href="#section2"><i class="fas fa-star"></i> Main Point 1</a></li>
        <li><a href="#section3"><i class="fas fa-lightbulb"></i> Main Point 2</a></li>
        <li><a href="#conclusion"><i class="fas fa-pencil-alt"></i> Conclusion</a></li>
        <li><a href="#section3"><i class="fas fa-lightbulb"></i> Main Point 2</a></li>
        <li><a href="#conclusion"><i class="fas fa-pencil-alt"></i> Conclusion</a></li>
    </ul>
</div> */}