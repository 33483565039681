import { BlogPosts } from "./BlogPosts";
import { NavLink } from "react-router-dom";
import moment from "moment/moment";
import DisplayPost from "../../components/DisplayPost/DisplayPost";
import { useState } from "react";
import PostCardSmall from "../../components/DisplayPost/PostCardSmall";
import BlogJumbo from "../../components/Jumbotron/BlogJumbo";
import { Helmet } from "react-helmet";
import { MetaData } from "../../MetaData/MetaData";

export default function BlogListings() {

    // Sidebar Styls
    const sideBar = {
        top: '5rem'
    }

    // Background Color for the featured post
    const mainContainer = {
        backgroundColor: 'var(--transparentBackground)'

    };

    // Latest blog post will always be index 0
    const LatestPost = BlogPosts[0];

    // Function to check for the featured blog post
    const Featured = () => {
        for (let i = 0; i < BlogPosts.length; i++) {

            if(BlogPosts[i].featured) {
                return BlogPosts[i];

            };
        };
    };

    
    // Get a random blog post
    const getRandomPost = () => {

        // For the sake of the first blog post, allow the random post to be the only post.
        if (BlogPosts.length <= 1) {
            return BlogPosts[0];

        }

        let post = BlogPosts[Math.floor(Math.random() * BlogPosts.length)];

        // Check not the featured post
        if (!post.featured) {
            return post;

        } else {
            console.log(post.featured);
            return getRandomPost();

        };

    };

    const randomPost = getRandomPost();

    const [filteredPosts, setFilteredPosts] = useState(BlogPosts)
    const [currentTag, setCurrentTag] = useState()

  // Function to create filters from available posts
  var filterTag = [];

  const filters = () => {
    BlogPosts.forEach(e => {

      for (let i = 0; i < e.tags.length; i++) {
        filterTag.push(e.tags[i]);

      };
    });

    filterTag = filterTag.filter((value, index) => filterTag.indexOf(value) === index);
  };

  filters();
  
  // Function to filter the posts list

  const filterPosts = (tag) => {

    if(tag === currentTag) {
      setFilteredPosts(BlogPosts);
      setCurrentTag();

    } else {
      setCurrentTag(tag);
      let filtering = [];
      
      for (let i = 0; i < BlogPosts.length; i++) {
        for (let j = 0; j < BlogPosts[i].tags.length; j++) {

          if(BlogPosts[i].tags[j] === tag){
            filtering.push(BlogPosts[i]);

          };
        };
      };

    setFilteredPosts(filtering);
    };
  }

    return(
    <>
        <Helmet>
            <title>{MetaData.my_stuff.page_title}</title>
            <meta name="title" content={MetaData.my_stuff.meta_title} />
            <meta name="description" content={MetaData.my_stuff.meta_description} />
            <meta property="og:title" content={MetaData.my_stuff.meta_title} />
            <meta property="og:description" content={MetaData.my_stuff.meta_description} />
            <meta property="og:url" content="https://www.rob-saunders.co.uk/my-stuff" />
            <meta property="og:type" content="website" />
        </Helmet>

        <BlogJumbo 
            h1="Here's what I've been up to"
            p="While I absolutely LOVE LOVE LOVE coding, websites and all things nerd, the reason I do it all is to live! My passions are Hiking, The Outdoors, Surfing and adventure. "
            img="hero-image.png"

        />  

        <main className="container mt-5">
            <NavLink to={LatestPost.url}>
                <div className="p-4 p-md-5 mb-3 text-body-emphasis shadow row"  style={mainContainer} >
                    <div className="col-lg-6 d-flex align-content-between flex-wrap">
                        <div>
                            <h2 className="display-6"><NavLink to={LatestPost.url}>{LatestPost.postTitle}</NavLink></h2>
                            <p className="lead my-3">{LatestPost.postDescription.substring(0 , 300)}...</p>
                        </div>
                        <p className="lead mb-0"><NavLink to={LatestPost.url} className="text-body-emphasis fw-bold">Continue reading...</NavLink></p>
                    </div>
                    <div className="p-0 mt-3 col-lg-6">
                        <img className="w-100 h-100 object-fit-cover" src={LatestPost.postMainImage} alt={LatestPost.main_image_alt} />
                    </div>

                    
                </div>
            </NavLink>

            <div className="row mb-2">
                <div className="col-md-6">
                    <div className="card-container row g-0 border overflow-hidden flex-md-row mb-4 shadow h-md-250 d-flex align-items-stretch position-relative">
                        <div className="col-7 p-4 d-flex flex-column position-static">
                            <strong className="d-inline-block mb-2 text-primary-emphasis">{Featured().tags.join(' | ')}</strong>
                            <h3 className="mb-0 text-brand-purple">Featured post</h3>
                            <div className="mb-1 text-body-secondary">{moment(Featured().date).format('MMMM Do YYYY')}</div>
                            <p className="card-text mb-auto">{Featured().postTitle}</p>
                            <NavLink to={'/my-stuff/'+Featured().url} className=" gap-1 icon-link-hover stretched-link">
                                Continue reading...
                            </NavLink>
                        </div>
                        <div className="col-5 d-none d-lg-block">
                            <div className="h-100">
                                <img className="w-100 h-100 object-fit-cover" src={Featured().introImage} alt={Featured().intro_image_alt} ></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card-container row g-0 border overflow-hidden flex-md-row mb-4 shadow h-md-250 d-flex align-items-stretch position-relative">
                        <div className="col-7 p-4 d-flex flex-column position-static">
                            <strong className="d-inline-block mb-2 text-primary-emphasis">{randomPost.tags.join(' | ')}</strong>
                            <h3 className="mb-0 text-brand-purple">Random Post</h3>
                            <div className="mb-1 text-body-secondary">{moment(randomPost.date).format('MMMM Do YYYY')}</div>
                            <p className="card-text mb-auto">{randomPost.postTitle}</p>
                            <NavLink to={'/my-stuff/'+randomPost.url} className=" gap-1 icon-link-hover stretched-link">
                                Continue reading...
                            </NavLink>
                        </div>
                        <div className="col-5 d-none d-lg-block">
                        <div className="h-100">
                            <img className="w-100 h-100 object-fit-cover" src={randomPost.introImage} alt={randomPost.intro_image_alt}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-5 mt-3">
                <div className="col-md-8">
                    <div className="mb-5">                    
                        <h3 className="pb-4 mb-4 fst-italic border-bottom">
                            Post Categories
                        </h3>

                        <div className='filter-tags'>
                            {filterTag.map(filter => {
                                return(
                                <button onClick={() => filterPosts(filter)} className={`filter-button uniformWidth ${filter===currentTag ? "filter-button-active" : ""}`}>
                                    <span>{filter}</span>
                                    {filter===currentTag ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" className="filter-chevron" viewBox="0 0 329.26933 329"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
                                : ""}
                                </button>
                                );
                            })
                            }
                        </div>
                    </div>

                    <div className="row mb-2">
                        {filteredPosts.map( p => 
                            <PostCardSmall
                            key={p.id}
                            post={p}
                            />
                        )}
                    </div>

                    <article className="blog-post">
                        <h2 className="display-5 link-body-emphasis mb-1">{randomPost.postTitle}</h2>
                        <p className="blog-post-meta">{moment(randomPost.date).format('MMMM Do YYYY')}</p>

                        <div className="bg-white heavy-shadow">
                            {DisplayPost(randomPost)}
                        </div>
                        
                    </article>

                
                    <nav className="blog-pagination" aria-label="Pagination">
                        <a className="btn btn-outline-primary rounded-pill" href="#">Older</a>
                        <a className="btn btn-outline-secondary rounded-pill disabled" aria-disabled="true">Newer</a>
                    </nav>

                </div>

                <div className="col-md-4">
                <div className="position-sticky" style={sideBar}>
                    <div className="p-4 mb-3 bg-body-tertiary rounded">
                    <h4 className="fst-italic">About</h4>
                    <p className="mb-0">This section is just a place for me to journal what I have been up to.</p>
                    </div>

                    <div>
                        <h4 className="fst-italic">Recent posts</h4>
                        <ul className="list-unstyled">
                            {BlogPosts.map(recent => {
                                return <li>
                                    <a className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top" href={recent.url}>
                                        <img className="bd-placeholder-img" width="55%" src={recent.postMainImage} />
                                        <div className="col-lg-8">
                                            <h6 className="mb-0">{recent.postTitle}</h6>
                                            <small className="text-body-secondary">{moment(recent.date).format('MMMM Do YYYY')}</small>
                                        </div>
                                    </a>
                                </li>
                            })}
                        </ul>
                    </div>

                    <div className="p-4">
                    <h4 className="fst-italic">Categories</h4>
                    <ol className="list-unstyled mb-0">
                        {/* Map over the blog categories */}
                        {filterTag.map(filter => {
                                return(
                                    <li><a style={{cursor: 'pointer'}} onClick={() => filterPosts(filter)}>{filter}</a></li>
                                );
                            }
                        )}

                    </ol>
                    </div>

                    <div className="p-4">
                    <h4 className="fst-italic">Elsewhere</h4>
                    <ol className="list-unstyled">
                        <li><a target="_blank" href="https://github.com/R0bsaunders">GitHub</a></li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
        </main>
    </>
    )
}